<template>
  <div id="ingresos" class="mt-4">
    <h4 class="text-primary mt-3 mb-3 font-weight-bold">Ingresos</h4>
    <div class="mb-3 text-right">
      <button
        v-on:click="load_search = !load_search"
        class="btn btn-info btn-rounded btn-sm ml-2"
      >
        <i class="fa fa-search"></i> Buscar ingreso
      </button>
      <button
        v-on:click="load_filtro = !load_filtro"
        class="btn btn-info btn-rounded btn-sm ml-2"
      >
        <i class="fa fa-filter"></i> Filtrar
      </button>
      <button
        v-if="ingresos.length != 0"
        v-on:click="download"
        class="btn btn-success btn-rounded btn-sm ml-2"
      >
        <i class="fa fa-download"></i> Descargar
      </button>
    </div>
    <transition v-if="load_search" appear name="slide-fade">
      <div class="mb-3 mt-2">
        <input
          v-model="search"
          v-on:keyup="search_ingreso"
          type="text"
          class="form-control my-form-control"
          placeholder="Buscar ingreso por código..."
        />
      </div>
    </transition>
    <transition v-if="load_filtro" appear name="slide-fade">
      <div class="mb-3 mt-2">
        <div class="form-group">
          <label>Localidad</label>
          <select
            v-on:change="get_ingresos"
            v-model="filtro.id_localidad"
            class="form-control"
          >
            <option
              v-for="(localidad, index_localidad) in localidades"
              v-bind:key="index_localidad"
              v-bind:value="localidad.id_localidad"
            >
              {{ localidad.localidad }}
            </option>
          </select>
        </div>
        <div class="text-right">
          <a
            v-on:click="
              (filtro.id_localidad = null),
                (load_filtro = false),
                get_entradas()
            "
            href="javascript:void(0)"
            class="text-danger"
          >
            <i class="fa fa-times"></i> Quitar filtros
          </a>
        </div>
      </div>
    </transition>
    <div class="mt-2 mb-2">
      <strong>{{ total_ingresos }} ingresos registrados.</strong>
    </div>
    <div class="table-responsive pt-3">
      <table class="table my_table table-bordered table-hover">
        <thead>
          <tr>
            <th class="text-center">#</th>
            <th>Localidad</th>
            <th>Código</th>
            <th>Ingreso</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(entrada, index_entrada) in ingresos"
            v-bind:key="index_entrada"
          >
            <td class="text-center" style="width: 5%">{{ entrada.num }}.</td>
            <td style="width: 30%">
              {{ entrada.localidad }}
            </td>
            <td style="width: 30%">
              <strong>{{ entrada.codigo }}</strong>
            </td>
            <td>
              <span v-if="entrada.ingreso == null" class="text-muted">
                <i class="fa fa-exclamation-circle"></i> No hay registro de
                ingreso.
              </span>
              <span v-else>
                Ingreso registrado por:
                <strong>{{ entrada.ingreso.usuario }}</strong> <br />
                Hora de ingreso:
                <strong>{{ entrada.ingreso.hora }}</strong> <br />
                Fecha y hora de registro:
                <strong>{{ entrada.ingreso.fecha }}</strong>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mt-4 mb-4" v-html="paginate"></div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "Ingresos",
  metaInfo: {
    title: "Ingresos del evento - Taqui",
  },
  data() {
    return {
      loading: true,
      id_evento: null,
      page: null,
      search: null,
      ingresos: [],
      total_ingresos: 0,
      paginate: null,
      localidades: [],
      edit: false,
      index_select: null,
      load_search: false,
      load_filtro: false,
      filtro: {
        id_localidad: null,
      },
      form_upload: false,
      file: [],
      hay_archivo: false,
      msg_upload: {
        type: null,
        text: null,
      },
      subidas: [],
      subida_select: null,
    };
  },
  props: {
    evento: Object,
    perfil: String,
  },
  created: function () {
    this.get_ingresos();
    this.get_localidades();
  },
  methods: {
    get_ingresos: function () {
      this.id_evento = this.$route.params.id_evento;
      this.page = this.$route.params.page;
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        token: token_session,
      };
      let config = {
        headers: headers,
        params: {
          page: this.page,
          search: this.search,
          id_localidad: this.filtro.id_localidad,
        },
      };
      axios
        .get(this.$base_url + "eventos/ingresos/" + this.id_evento, config, {
          headers: headers,
        })
        .then((response) => {
          if (response.data.status) {
            this.ingresos = response.data.data.ingresos;
            this.total_ingresos = response.data.data.total_ingresos;
            this.get_paginate(response.data.total_pages);
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }
        })
        .catch()
        .finally();
    },
    get_paginate: function (tpages) {
      var page = this.page;

      if (page === undefined || page <= 0) {
        this.page = 1;
        page = this.page;
      }

      var adjacents = 4;

      var paginate = null;

      paginate = '<ul class="pagination justify-content-center text-center">';
      // previous label
      if (page == 1) {
        paginate +=
          "<li class='paginate_button page-item previous disabled'><a class='page-link disabled'> <i class='fa fa-chevron-left'></i> </a></li>";
      } else if (page == 2) {
        paginate +=
          "<li class='paginate_button page-item  previous'><a href='#/entradas/" +
          this.id_evento +
          "/1' class='page-link' href='javascript:void(0);'> <i class='fa fa-chevron-left'></i> </a></li>";
      } else {
        var page_ant = parseFloat(page) - 1;
        paginate +=
          "<li class='paginate_button page-item'><a href='#/entradas/" +
          this.id_evento +
          "/" +
          page_ant +
          "' class='page-link' href='javascript:void(0);'> <i class='fa fa-chevron-left'></i> </a></li>";
      }
      // first label
      if (page > parseFloat(adjacents) + 1) {
        paginate +=
          "<li class='paginate_button page-item'><a class='page-link' href='#/entradas/" +
          this.id_evento +
          "/1'>1</a></li>";
      }
      // interval
      if (page > parseFloat(adjacents) + 2) {
        paginate += "<li class='paginate_button page-item'> <a> ... </a> </li>";
      }
      // pages
      var pmin =
        page > adjacents ? parseFloat(page) - parseFloat(adjacents) : 1;
      var pmax =
        page < parseFloat(tpages) - parseFloat(adjacents)
          ? parseFloat(page) + parseFloat(adjacents)
          : tpages;

      for (var i = pmin; i <= pmax; i++) {
        if (i == page) {
          paginate +=
            "<li class='paginate_button page-item active'><a href='#/entradas/" +
            this.id_evento +
            "/" +
            i +
            "' class='page-link'>" +
            i +
            "</a></li>";
        } else if (i == 1) {
          paginate +=
            "<li class='paginate_button page-item'><a href='#/entradas/" +
            this.id_evento +
            "/" +
            i +
            "' class='page-link' href='javascript:void(0);'>" +
            i +
            "</a></li>";
        } else {
          paginate +=
            "<li class='paginate_button page-item'><a href='#/entradas/" +
            this.id_evento +
            "/" +
            i +
            "' class='page-link' href='javascript:void(0);'>" +
            i +
            "</a></li>";
        }
      }
      // interval
      if (page < parseFloat(tpages) - parseFloat(adjacents) - 1) {
        paginate += "<li class='paginate_button page-item'> <a> ... </a> </li>";
      }
      // last
      if (page < parseFloat(tpages) - parseFloat(adjacents)) {
        paginate +=
          "<li class='paginate_button page-item'><a  href='#/entradas/" +
          this.id_evento +
          "/" +
          tpages +
          "' class='page-link' href='javascript:void(0);'>" +
          tpages +
          "</a></li>";
      }
      // next
      if (page < tpages) {
        var page_next = parseFloat(page) + 1;
        paginate +=
          "<li class='paginate_button page-item next'><a  href='#/entradas/" +
          this.id_evento +
          "/" +
          page_next +
          "' class='page-link' href='javascript:void(0);'> <i class='fa fa-chevron-right'></i> </a></li>";
      } else {
        paginate +=
          "<li class='paginate_button page-item next disabled'><a class='page-link disabled'> <i class='fa fa-chevron-right'></i> </a></li>";
      }
      paginate += "</ul>";
      this.paginate = paginate;
    },
    get_localidades: function () {
      this.id_evento = this.$route.params.id_evento;
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      this.page = this.$route.params.page;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        token: token_session,
      };
      let config = {
        headers: headers,
      };
      axios
        .get(this.$base_url + "eventos/localidades/" + this.id_evento, config, {
          headers: headers,
        })
        .then((response) => {
          if (response.data.status) {
            this.localidades = response.data.data;
          }
        })
        .catch()
        .finally();
    },
    load_editar: function (index) {
      this.entrada = this.entradas[index];
      this.index_select = index;
      this.edit = true;
    },
    search_ingreso: function () {
      if (this.search != null) {
        if (this.search.length >= 3) {
          this.page = 1;
          this.get_ingresos();
        } else {
          if (this.search == "" || this.search.length == 0) {
            this.search = null;
            this.get_ingresos();
          }
        }
      }
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        this.hay_archivo = false;
        return;
      }
      this.file = files;
      this.hay_archivo = true;
    },
    download: function () {
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      var id_evento = this.$route.params.id_evento;
      window.open(
        this.$base_url + "download/ingresos/" + token_session + "/" + id_evento,
        "_blank"
      );
    },
  },
  watch: {
    $route() {
      this.page = this.$route.params.page;
      this.get_ingresos();
    },
  },
};
</script>